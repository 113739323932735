.update-content {
    padding: 20px;
    margin: 0 auto;
}

.error-message {
    background-color: #fed7d7;
    border: 1px solid #f56565;
    color: #c53030;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.content-type-select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
}

.content-section {
    margin-bottom: 20px;
}

.content-textarea {
    width: 97%;
    min-height: 150px;
    padding: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    resize: vertical;
}

.url-section {
    margin-bottom: 20px;
}

.url-input-container {
    width: 100%;
}

.url-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
}

.video-section {
    margin-bottom: 20px;
}

.file-input-container {
    margin-bottom: 15px;
}

.file-requirements {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 10px;
}

.file-info {
    font-size: 0.9em;
    color: #2c5282;
    margin-top: 5px;
}

.video-preview {
    margin: 15px 0;
}

.button-container {
    margin-top: 20px;
}

.upload-button, .update-button {
    background-color: #4299e1;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.upload-button:hover, .update-button:hover {
    background-color: #3182ce;
}

.upload-button:disabled, .update-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Новые стили для кнопок */
.buttons-section {
    margin-top: 20px;
    padding: 20px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
}

.buttons-container {
    display: grid;
    gap: 20px;
    margin: 20px 0;
}

.button-group {
    background: white;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    border: 1px solid #e2e8f0;
}

.button-group h4 {
    margin: 0 0 15px 0;
    color: #2d3748;
    font-size: 16px;
}

.input-group {
    margin: 12px 0;
}

.input-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #4a5568;
    font-size: 14px;
}

.text-input,
.url-input {
    width: calc(100% - 16px);
    padding: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.2s;
}

.text-input:focus,
.url-input:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
}

.success-alert {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 16px 24px;
    background-color: #48bb78;
    color: white;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slideIn 0.3s ease-out;
}

.success-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.success-icon {
    font-size: 20px;
    font-weight: bold;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dialog-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 20px;
}

.cancel-button {
    padding: 8px 16px;
    background-color: #e2e8f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirm-button {
    padding: 8px 16px;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button:hover {
    background-color: #cbd5e0;
}

.confirm-button:hover {
    background-color: #3182ce;
}