.send-message {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.send-message h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

/* User Status Section */
.users-status-section {
    margin: 20px 0;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.users-status-section h3 {
    margin-bottom: 15px;
    color: #333;
}

.user-list {
    margin-top: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    background: white;
}

.user-list-header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1.5fr;
    gap: 10px;
    padding: 12px;
    background: #f0f0f0;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
}

.user-item {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1.5fr;
    gap: 10px;
    padding: 12px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    transition: background-color 0.2s;
}

.user-item:last-child {
    border-bottom: none;
}

.user-item:hover {
    background: #f8f8f8;
}

.user-item.inactive {
    background: #fff5f5;
}

.status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.9em;
    text-align: center;
    display: inline-block;
    min-width: 80px;
}

.status-badge.active {
    background: #e6ffe6;
    color: #006600;
    border: 1px solid #00660033;
}

.status-badge.inactive {
    background: #ffe6e6;
    color: #cc0000;
    border: 1px solid #cc000033;
}

.refresh-button {
    margin-top: 15px;
    padding: 8px 16px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
}

.refresh-button:hover {
    background: #45a049;
    transform: translateY(-1px);
}

.refresh-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
}

/* Message Form */
.message-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.text-input-container {
    margin-bottom: 20px;
}

.message-textarea {
    width: 96%;
    min-height: 120px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    font-family: inherit;
}

.message-textarea:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.media-type-selector {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    padding: 15px;
    background: #f5f5f5;
    border-radius: 4px;
}

.media-type-selector label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.media-type-selector input[type="radio"] {
    margin: 0;
}

.file-input-container {
    margin-bottom: 20px;
    padding: 15px;
    background: #f5f5f5;
    border-radius: 4px;
}

.file-input {
    display: block;
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.media-options {
    margin-top: 10px;
}

.checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

/* Buttons Section */
.buttons-section {
    margin: 20px 0;
    padding: 15px;
    background: #f5f5f5;
    border-radius: 4px;
}

.button-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.button-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.button-input:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.remove-button {
    padding: 8px 16px;
    background: #ff4444;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

.remove-button:hover {
    background: #cc0000;
}

.remove-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.add-button {
    margin-top: 10px;
    padding: 8px 16px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

.add-button:hover {
    background: #45a049;
}

.add-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

/* Media Preview */
.media-preview {
    margin: 20px 0;
    text-align: center;
}

.media-preview img,
.media-preview video {
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Send Button */
.send-button {
    width: 100%;
    padding: 12px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.send-button:hover {
    background: #45a049;
    transform: translateY(-1px);
}

.send-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
}

.send-button.loading {
    background: #cccccc;
    cursor: wait;
}

/* Status Message */
.status-message {
    margin-top: 15px;
    padding: 12px;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
}

.status-message.success {
    background: #e6ffe6;
    color: #006600;
    border: 1px solid #00660033;
}

.status-message.error {
    background: #ffe6e6;
    color: #cc0000;
    border: 1px solid #cc000033;
}

/* Loading Overlay */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #4CAF50;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loading-text {
    margin-top: 15px;
    color: #333;
    font-weight: 500;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .user-list-header,
    .user-item {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .user-list-header span:nth-child(4),
    .user-list-header span:nth-child(5),
    .user-item span:nth-child(4),
    .user-item span:nth-child(5) {
        display: none;
    }

    .button-inputs {
        flex-direction: column;
    }

    .remove-button {
        width: 100%;
    }

    .media-type-selector {
        flex-direction: column;
        gap: 10px;
    }
}